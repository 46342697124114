import { UserManagementState, ActionType } from './types';
import { userConstants } from './constants';

// INITIAL STATE
const initialState: UserManagementState = {
  userList: [],
  totalUsers: 0,
  importList: [],
  importCount: 0,
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
// TODO: is user === account ? Change account
export const userReducer = (state = initialState, action: ActionType): UserManagementState => {
  switch (action.type) {
    case userConstants.USER_SET_ALL:
      return {
        ...state,
        userList: action.payload.users || [],
        totalUsers: action.payload.totalUsers || 0,
      };

    case userConstants.USER_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case userConstants.USER_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case userConstants.USER_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case userConstants.USER_ADD:
      return {
        ...state,
        userList: [...state.userList, action.payload],
      };

    case userConstants.USER_REPLACE:
      return {
        ...state,
        userList: state.userList.map((user) => (user._id === action.payload._id ? action.payload : user)),
      };

    case userConstants.USER_DELETE:
      return {
        ...state,
        userList: state.userList.filter((user) => user._id !== action.payload),
      };

    case userConstants.USER_IMPORT_LIST_SET_ALL:
      return {
        ...state,
        importList: action.payload || [],
      };

    case userConstants.USER_IMPORT_LIST_SET_COUNT:
      return {
        ...state,
        importCount: action.payload || 0,
      };

    case userConstants.USER_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
