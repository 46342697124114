import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Container, Box, useMediaQuery, Link } from '@material-ui/core';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { PageProps } from './types';

import { Notifier } from '../../../../features/Notifier';
import { Wave } from '../../../molecules/layout/Wave';
import { BaseSideBar } from '../../../organisms/drawer/BaseSideBar';
import { RootState } from '../../../../lib/store';
import { useCheckPlan } from '../../../../lib/hooks/useCheckPlan';
import { availablePlans } from '../../../../lib/constants/availablePlans';
import { RouterLink } from '../../../molecules/link/RouterLink';
import { AuthRoutes } from '../../../../lib/constants/pagePaths';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    container: {
      position: 'relative',
      zIndex: theme.zIndex.drawer - 1,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: '100%',
      overflowX: 'auto',
    },
    sliderContainer: {
      position: 'relative',
      zIndex: theme.zIndex.drawer - 1,
      paddingTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      overflowX: 'auto',
    },
    sliderContainerDense: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      minHeight: ({ toolbarHeight }: { toolbarHeight: string }) => toolbarHeight,
      // necessary for content to be below app bar
      // ...theme.mixins.toolbar,
    },
    boxUpgradeWrap: {
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
      fontSize: '12px',
      textAlign: 'center',
      height: ({ boxUpgradeHeight }: { boxUpgradeHeight: string; toolbarHeight: string }) => boxUpgradeHeight,
    },
  }),
);

export const HEADER_HEIGHT = '70px';
const FOOTER_HEIGHT = '50px';
const TOC_HEIGHT = '30px';
const BOX_UPGRADE_HEIGHT = 18;

export const BaseTemplate: React.FC<PageProps> = ({
  header,
  footer,
  toc,
  sideBarItems,
  moduleTitle,
  waveOrientation,
  withPixel,
  stretch,
  sliderMode = false,
  children,
  mode,
}: PageProps): ReactElement => {
  const { current: account } = useSelector((state: RootState) => state.account);
  const { isRootUser } = account || {};
  const { hasPlan } = useCheckPlan(availablePlans);
  const theme = useTheme();
  const isMinTablet = useMediaQuery(theme.breakpoints.up('sm'));

  const showTopBar = !hasPlan && isRootUser && isMinTablet;
  const baseToolbarHeight = isMinTablet ? 64 : 48;

  const styleProps = {
    headerHeight: header ? HEADER_HEIGHT : '0px',
    footerHeight: footer ? FOOTER_HEIGHT : '0px',
    tocHeight: toc ? TOC_HEIGHT : '0px',
    boxUpgradeHeight: `${BOX_UPGRADE_HEIGHT}px`,
    toolbarHeight: `${showTopBar ? BOX_UPGRADE_HEIGHT + baseToolbarHeight : baseToolbarHeight}px`,
  };
  const classes = useStyles(styleProps);

  let topBar = null;

  if (showTopBar) {
    topBar = (
      <Box className={classes.boxUpgradeWrap}>
        <span>
          <Trans
            i18nKey="init:shared.pages.upgradeTextHeader"
            components={{
              upgrade: <Link component={RouterLink} to={AuthRoutes.billing} />,
            }}
          />
        </span>
      </Box>
    );
  }

  if (sliderMode) {
    return (
      <Box height="100%" display="flex">
        {header ? header(topBar) : null}
        {sideBarItems && sideBarItems.length > 0 && moduleTitle ? (
          <BaseSideBar toolbarHeight={styleProps.toolbarHeight} moduleTitle={moduleTitle} sideBarItems={sideBarItems} />
        ) : null}
        <Container
          id="main-container"
          maxWidth="xl"
          className={`${classes.sliderContainer} ${mode === 'embed' ? classes.sliderContainerDense : ''}`}
        >
          {header && <div className={classes.toolbar} />}
          {children}
          {header && <Notifier />}
        </Container>
        {waveOrientation.right && <Wave orientation="right" withPixel={!!withPixel.right} />}
      </Box>
    );
  }

  return (
    <Box height={stretch ? '100%' : 'auto'} display="flex">
      {waveOrientation.top && <Wave orientation="top" withPixel={!!withPixel.top} />}
      {header ? header(topBar) : null}
      {sideBarItems && sideBarItems.length > 0 && moduleTitle ? (
        <BaseSideBar toolbarHeight={styleProps.toolbarHeight} moduleTitle={moduleTitle} sideBarItems={sideBarItems} />
      ) : null}
      <Container id="main-container" maxWidth="xl" className={classes.container}>
        {header && <div className={classes.toolbar} />}
        {children}
        {header && <div className={classes.toolbar} />}
        {header && <Notifier />}
      </Container>
      {waveOrientation.right && <Wave orientation="right" withPixel={!!withPixel.right} />}
      {waveOrientation.bottom && <Wave orientation="bottom" withPixel={!!withPixel.bottom} />}
      {footer ? footer() : null}
      {toc ? toc() : null}
    </Box>
  );
};

BaseTemplate.propTypes = {
  header: PropTypes.func,
  footer: PropTypes.func,
  toc: PropTypes.func,
  sideBarItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    }).isRequired,
  ),
  moduleTitle: PropTypes.string,
  stretch: PropTypes.bool,
  sliderMode: PropTypes.bool,
  waveOrientation: PropTypes.shape({
    bottom: PropTypes.bool,
    right: PropTypes.bool,
    top: PropTypes.bool,
  }).isRequired,
  withPixel: PropTypes.shape({
    bottom: PropTypes.bool,
    right: PropTypes.bool,
    top: PropTypes.bool,
  }).isRequired,
};

BaseTemplate.defaultProps = {
  toc: undefined,
  header: undefined,
  footer: undefined,
  sideBarItems: undefined,
  moduleTitle: undefined,
  stretch: false,
  sliderMode: false,
};
