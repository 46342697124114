import { hasAccountRequiredPermission } from '.';

import { AvailablePermissions } from '../constants/availablePermissions';
import { PermissionType } from '../store/role/types';

export const hasCompanyStructurePermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [
    AvailablePermissions.CoreCompanystructureFind,
    AvailablePermissions.CoreEmployeetypesFind,
    AvailablePermissions.CoreGroupadministrationFind,
  ]);
};

export const hasBillingPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [
    AvailablePermissions.CoreCrosssystemSettingsBillingFind,
    AvailablePermissions.CoreCrosssystemSettingsBillingUpdate,
  ]);
};

export const hasUserManagementPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [
    AvailablePermissions.CoreUseradministrationFind,
    AvailablePermissions.CoreUseradministrationCreate,
    AvailablePermissions.CoreUseradministrationUpdate,
    AvailablePermissions.CoreUseradministrationDelete,
    AvailablePermissions.CoreUseradministrationImport,
  ]);
};

export const hasRoleManagementPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [
    AvailablePermissions.CoreRoleFind,
    AvailablePermissions.CoreRoleCreate,
    AvailablePermissions.CoreRoleUpdate,
    AvailablePermissions.CoreRoleDelete,
  ]);
};

export const hasSystemSettingsPermissions = (permissions: PermissionType[]): boolean => {
  return hasAccountRequiredPermission(permissions || [], [
    AvailablePermissions.CoreCrosssystemSettingsFind,
    AvailablePermissions.CoreCrosssystemSettingsUpdate,
  ]);
};
