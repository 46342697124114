import { Dispatch } from 'redux';
import { thunk } from './thunks';
import {
  apiCreateUser,
  apiGetUsers,
  apiUpdateUser,
  apiDeleteUser,
  apiImportVerifyUsers,
  apiImportUsers,
} from './services';
import {
  setLoading,
  addUser,
  successUser,
  setUserList,
  replaceUser,
  deleteUser,
  setImportList,
  setImportCount,
} from './actions';
import { GetStateType } from '..';
import { CreateUserType } from './types';

import { QueryObject } from '../../httpClient';
import { enqueueSnackbar } from '../notifier/actions';
import { ActionType as UserActionType } from '../../context/user/types';
import { actionTypes } from '../../context/user/constants';

export const attemptGetUsers = (
  filter?: QueryObject,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    const { users, totalUsers } = await apiGetUsers(filter);
    dispatch(setUserList({ users, totalUsers }));
  });

export const attemptCreateUser = (
  body: CreateUserType,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('userAdd'));
    const user = await apiCreateUser(body);
    dispatch(addUser(user));
    dispatch(successUser('userAdd'));
    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:pages.userManagement.actions.userAddSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptUpdateUser = (
  _id: string,
  body: Partial<CreateUserType>,
  additionalDispatch?: React.Dispatch<UserActionType>,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('userEdit'));
    if (Object.keys(body).length > 0) {
      const user = await apiUpdateUser(_id, body);
      dispatch(replaceUser(user));

      dispatch(successUser('userEdit'));
      if (additionalDispatch) {
        additionalDispatch({
          type: actionTypes.UPDATE_CURRENT,
          payload: { data: user },
        });
      }

      dispatch(
        enqueueSnackbar({
          translationString: 'module-core:pages.userManagement.actions.userUpdateSuccess',
          messageTranslationKeys: {},
          options: {
            variant: 'success',
          },
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          translationString: 'module-core:pages.userManagement.actions.userUpdateNoChange',
          messageTranslationKeys: {},
          options: {
            variant: 'warning',
          },
        }),
      );
    }
  });

export const attemptDeleteUser = (_id: string): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('userDelete'));
    await apiDeleteUser(_id);
    dispatch(deleteUser(_id));
    dispatch(successUser('userDelete'));

    dispatch(
      enqueueSnackbar({
        translationString: 'module-core:pages.userManagement.actions.userDeleteSuccess',
        messageTranslationKeys: {},
        options: {
          variant: 'success',
        },
      }),
    );
  });

export const attemptImportVerifyUsers = (
  formData: FormData,
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('userImportVerify'));
    dispatch(setImportList([]));
    const importList = await apiImportVerifyUsers(formData);
    dispatch(setImportList(importList));
    dispatch(successUser('userImportVerify'));
  });

const CHUNK_SIZE = 100;

export const attemptImportUsers = (
  data: CreateUserType[],
): ((dispatch: Dispatch, getState: GetStateType) => Promise<void>) =>
  thunk(async (dispatch: Dispatch) => {
    dispatch(setLoading('userImport'));
    let importedAmount = 0;

    // call in chunks of 100
    while (importedAmount < data.length) {
      const chunk = data.slice(importedAmount, importedAmount + CHUNK_SIZE);

      // eslint-disable-next-line no-await-in-loop
      await apiImportUsers(chunk);
      importedAmount += CHUNK_SIZE;
      dispatch(setImportCount(importedAmount));
    }

    dispatch(successUser('userImport'));
    return undefined;
  });
