import React, { ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

import { ConfirmDialogProps } from './types';
import { useConfirmDialogStyles } from './styles';

export const ConfirmDialog = (props: ConfirmDialogProps): ReactElement => {
  const { open, handleClickCancel, handleClickConfirm, title, textReason, textCancel, textConfirm } = props;
  const classes = useConfirmDialogStyles();

  return (
    <Dialog maxWidth="sm" open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {typeof textReason === 'string' ? (
            <Typography className={classes.dialogText}>{textReason}</Typography>
          ) : (
            <div className={classes.dialogText}>{textReason}</div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancel} color="default" variant="text" autoFocus>
          {textCancel}
        </Button>
        <Button onClick={handleClickConfirm} color="secondary" variant="text" endIcon={<div />}>
          {textConfirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
