export enum userConstants {
  USER_SET_ALL = 'dSimple/user/SET_ALL',
  USER_LOADING = 'dSimple/user/LOADING',
  USER_ERRORED = 'dSimple/user/ERRORED',
  USER_SUCCESS = 'dSimple/user/SUCCESS',
  USER_ADD = 'dSimple/user/ADD',
  USER_REPLACE = 'dSimple/user/REPLACE',
  USER_DELETE = 'dSimple/user/DELETE',
  USER_IMPORT_LIST_SET_ALL = 'dSimple/user/USER_IMPORT_LIST_SET_ALL',
  USER_IMPORT_LIST_SET_COUNT = 'dSimple/user/USER_IMPORT_LIST_SET_COUNT',
  USER_INIT = 'dSimple/user/INIT',
}
