import React, { ReactElement } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { KeyboardArrowRightRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { PrimaryButtonProps } from './types';
import { usePrimaryButtonStyles } from './styles';

export const PrimaryButton = (props: PrimaryButtonProps): ReactElement => {
  const {
    loading,
    disabled,
    endIcon = <KeyboardArrowRightRounded />,
    children,
    variant = 'contained',
    color = 'primary',
    ...other
  } = props;
  const classes = usePrimaryButtonStyles();

  return (
    <Button
      color={color}
      variant={variant}
      className={clsx(variant !== 'text' && variant !== 'outlined' && classes.buttonRoot)}
      disabled={disabled || loading}
      endIcon={loading ? <CircularProgress size="24px" /> : endIcon}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children || ''}
    </Button>
  );
};
